@import '../../resources/vars';
@import '../../resources/mixins';

.wrapper {
  width: 100%;
}

.unchecked {
  --app-custom-input-controller-padding: var(--input-checkbox-outer-container-padding);
  --app-custom-input-controller-background: var(--input-checkbox-outer-container-background);
  --app-custom-input-controller-border: var(--input-checkbox-outer-container-border);
  --app-custom-input-controller-border-radius: var(--input-checkbox-outer-container-border-radius);

  --app-custom-input-label-color: var(--input-checkbox-label-colour);
  --app-custom-input-label-opt2-color: var(--input-checkbox-label-opt2-colour);

  --app-custom-checkbox-option-explainer-color: var(--input-checkbox-option-explainer-colour-off);

  --app-custom-input-image-border: var(--input-checkbox-image-border);
  --app-custom-input-sub-label-color: var(--input-checkbox-sub-label-colour);

}

.checked {
  --app-custom-input-controller-background: var(--input-checkbox-outer-container-background-on);
  --app-custom-input-controller-border: var(--input-checkbox-outer-container-border-on);

  --app-custom-input-label-color: var(--input-checkbox-label-colour-on);
  --app-custom-input-label-opt2-color: var(--input-checkbox-label-opt2-colour-on);

  --app-custom-checkbox-option-explainer-color: var(--input-checkbox-option-explainer-colour-on);

  --app-custom-input-image-border: var(--input-checkbox-image-border-on);
  --app-custom-input-sub-label-color: var(--input-checkbox-sub-label-colour-on);
}
