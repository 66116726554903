@import '../resources/vars';
@import '../resources/mixins';

.container {
  align-items: var(--app-input-container-align-items, var(--input-container-align-items));
  border-color: var(--app-input-container-border-color, var(--input-container-border-color));
  border-style: var(--app-input-container-border-style, var(--input-container-border-style));
  border-width: var(--app-input-container-border-width, var(--input-container-border-width));
  display: flex;
  flex-grow: 1;
  flex-direction: var(--app-input-container-direction, var(--input-container-direction));
  justify-content: var(--app-input-container-justify-content, var(--input-container-justify-content));
  padding: var(--app-input-container-padding, var(--input-container-padding));
  width: 100%;
  max-width: var(--app-input-container-max-width, var(--input-container-max-width));
}

.wrapper {
  order: var(--app-input-label-order);

  align-items: var(--app-input-label-align-items, var(--input-label-align-items));
  display: flex;
  flex: 1;
  flex-direction: var(--app-input-label-direction, var(--input-label-direction));
  justify-content: var(--app-input-label-justify-content, var(--input-label-justify-content));
  margin: var(--app-input-label-margin, var(--input-label-margin));
}

.informer {
  background-color: var(--input-informer-background-color);
  border: var(--input-informer-border);
  border-radius: var(--input-informer-border-radius);
  box-shadow: var(--input-informer-box-shadow);
  margin: var(--app-input-informer-margin, var(--input-informer-margin));
  padding: var(--input-informer-padding);

  svg {
    height: var(--input-informer-icon-size);
    width: var(--input-informer-icon-size);
  }

  path {
    color: var(--input-informer-icon-colour);
  }
}

.no-informer {
  visibility: hidden;
}

.option-label {
  flex: 1 1 100%;
}

.image {
  width: var(--app-custom-input-image-size);
  height: var(--app-custom-input-image-size);
  border-radius: var(--app-custom-input-image-border-radius);
  margin: var(--app-custom-input-image-margin);
  border: var(--app-custom-input-image-border);


  @include respond-to(sm, up) {
    width: var(--app-custom-input-image-size-m);
    height: var(--app-custom-input-image-size-m);
    margin: var(--app-custom-input-image-margin-m);
  }

  @include respond-to(lg, up) {
    width: var(--app-custom-input-image-size-l);
    height: var(--app-custom-input-image-size-l);
    margin: var(--app-custom-input-image-margin-l);
  }
}

.sub-label {
  @include with-styled-text(app-custom-input-sub-label);
}

.label{
  display: var(--app-custom-input-labels-container-display, inline-block);
  flex-direction: var(--app-custom-input-labels-container-direction, row);
  width: 100%;
  gap: var(--app-custom-input-labels-container-gap);

  @include respond-to(sm, up) {
    gap: var(--app-custom-input-labels-container-gap-m);
  }

  @include respond-to(lg, up) {
    gap: var(--app-custom-input-labels-container-gap-l);
  }
}

.label-container {
  display: flex;
  flex: 1;
}
